.carousel {
  overflow: hidden;
}

.inner {
  white-space: nowrap;
  transition: transform 0.3s;
  height: 90px;
}

.carousel-item {
  display: inline-flex;
  align-items: flex-start;
  justify-content: center;
  height: 200px;
  color: #fff;
}

.indicators {
  display: flex;
  justify-content: center;
}

.indicators > button {
  margin: 5px;
}

.indicators > button.active {
  background-color: green;
  color: #fff;
}

.next-button {
  position: absolute;
  top: 80%;
  left: 80%;
}
.prev-button {
  position: absolute;
  top: 80%;
  right: 80%;
}

.active {
  border: 1px solid #f49432;
  background-color: #f49432;
}
.not-active {
  border: 1px solid #d9d9d9;
  background-color: #d9d9d9;
}

.buttons {
  display: flex;
  gap: 10px;
  justify-content: center;
  position: relative;
  top: -20px;
}
